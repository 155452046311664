/* eslint-disable object-curly-newline */
import { ref, reactive, provide, onMounted } from '@vue/composition-api'
import store from '@/store'
import { getAdAccounts, getChannelAccounts, getLastAccount, getUserData,
} from '@/auth/storage/handlers'

export default function useUserAccount() {
  const userData = ref(getUserData())
  const channelAccounts = reactive(getChannelAccounts() ?? [])
  const adAccounts = reactive(getAdAccounts() ?? [])

  onMounted(() => {
    const lastAccount = getLastAccount()
    if (lastAccount) {
      store.dispatch('account/onSelectAccount', lastAccount)
    }
  })

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  provide('userData', userData)
  provide('channelAccounts', channelAccounts)
  provide('adAccounts', adAccounts)
  return {
    userData,
    channelAccounts,
    adAccounts,
  }
}
