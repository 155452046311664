<template>
  <section>
    <!-- modal channel account -->
    <b-modal
      id="modal-channel-account"
      ref="refModal"
      title="채널계정명을 입력해주세요."
      ok-title="생성하기"
      cancel-title="닫기"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      hide-header-close
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="createAccount"
      >
        <b-form-group
          :state="nameState"
          label-for="name-input"
          invalid-feedback="계정명을 입력하세요"
        >
          <b-form-input
            id="name-input"
            v-model="accountName"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/named
import { channelAbility } from '@/libs/acl/config'
import { inject } from '@vue/composition-api'
import useAccountTask from '@/views/utils/task/useAccountTask'
import { channelRouteNames } from '@/router/routes/channel'
import { setChannelAccounts } from '@/auth/storage/handlers'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      accountName: '',
      nameState: null,
    }
  },
  methods: {
    createAccount() {
      if (!this.checkFormValidity()) {
        return
      }
      this.createChannelAccount(this.accountName).then(account => {
        if (account) {
          this.channelAccounts.push(account)
          setChannelAccounts(this.channelAccounts)
          this.$store.dispatch('account/onSelectAccount', account)
          this.$ability.update(channelAbility(account.permission))
          this.moveTo(account.id)
        }
        this.$refs.refModal.hide()
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: e,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.createAccount()
    },
    resetModal() {
      this.accountName = ''
      this.nameState = null
    },
    checkFormValidity() {
      this.nameState = this.accountName.length > 0
      return this.nameState
    },
    moveTo(id) {
      this.$router.push({
        name: channelRouteNames.account,
        params: {
          channelId: id,
        },
      })
    },
  },
  setup() {
    const channelAccounts = inject('channelAccounts')
    const { createChannelAccount } = useAccountTask()

    return {
      channelAccounts,
      createChannelAccount,
    }
  },
}
</script>

<style lang="scss">
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
