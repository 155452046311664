<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <navbar-brand />
      <h2 class="account-name mb-0">
        {{ $store.state.account.currentAccount.name }}
      </h2>
      <horizontal-nav-menu />
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import NavbarBrand from '@/views/components/app-navbar/NavbarBrand.vue'
import UserDropdown from '@/views/components/app-navbar/UserDropdown.vue'
import NotificationDropdown from '@/views/components/app-navbar/NotificationDropdown.vue'
import HorizontalNavMenu from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import useUserAccount from '@/views/utils/account/useUserAccount'

export default {
  components: {
    BLink,

    // Navbar Components
    NavbarBrand,
    BNavbarNav,
    HorizontalNavMenu,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    useUserAccount()
  },
}
</script>

<style lang="scss" scoped>
.account-name {
  white-space: nowrap;
  padding-left: 1rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 1.45rem;
}
</style>
