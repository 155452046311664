<template>
  <b-link
    class="navbar-brand"
    to="/"
  >
    <span class="brand-logo">
      <b-img
        :src="appLogoImage"
        alt="logo"
      />
    </span>
    <h2 class="brand-text mb-0">
      {{ appName }}
    </h2>
  </b-link>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 0;
  font-size: inherit;
  .brand-logo img {
    max-width: 36px;
  }
  .brand-text {
     color: var(--primary);
     padding-left: 1rem;
     margin-bottom: 0;
     font-weight: 600;
     letter-spacing: 0.01rem;
     font-size: 1.45rem;
  }
}
</style>
