<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          알림
        </h4>
        <b-badge
          pill
          variant="primary"
        >
          {{ unread }}개의 안읽은 알림
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Unread Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <b-media :class="!notification.read ? '' : 'bg-light-secondary'">
          <template #aside>
            <b-icon
              scale="1.5"
              :icon="notification.read ? notification.icon : `${notification.icon}-fill`"
              :variant="notification.type"
            />
            <b-icon
              v-if="!notification.read"
              icon="circle-fill"
              variant="primary"
              scale="0.5"
              shift-h="4"
              shift-v="10"
            />
          </template>
          <p class="media-heading">
            <span :class="!notification.read ? 'font-weight-bolder' : 'font-weight-normal'">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        알림 더보기
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton, BIcon,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BIcon,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const notifications = [
      {
        title: '승인',
        icon: 'check-circle',
        subtitle: 'Won the monthly best seller badge',
        type: 'info',
        read: false,
      },
      {
        title: '변경',
        icon: 'exclamation-circle',
        subtitle: 'You have 10 unread messages',
        type: 'warning',
        read: false,
      },
      {
        title: '반려',
        icon: 'x-circle',
        subtitle: 'MD Inc. order updated',
        type: 'danger',
        read: false,
      },
      {
        title: '비활성',
        icon: 'x-circle',
        subtitle: 'Already read',
        type: 'danger',
        read: true,
      },
    ]

    const unread = computed(() => notifications.filter(el => !el.read).length)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      unread,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
