<template>
  <b-nav-item-dropdown
    ref="user-dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <user-avatar
        id="user-popover"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          <strong>{{ userData.name }}</strong>
          님, 어서오세요!
        </h4>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div class="d-flex align-items-center mx-1 my-1 justify-content-between">
        <strong class="flex-grow-1">채널계정</strong>
        <b-button
          v-if="!isAdmin"
          v-b-modal.modal-channel-account
          style="margin-left: auto"
          pill
          size="sm"
          variant="primary"
        >
          <b-icon
            icon="plus"
          />
          추가
        </b-button>
      </div>
      <b-list-group
        flush
        class="account-group"
      >
        <b-list-group-item
          v-for="account in channelAccounts"
          :key="account.id"
          :active="account.id.toString() === current.id && account.type === current.type"
          :disabled="account.status==='DISABLED'"
          button
          @click="onSelectChannelAccount(account)"
        >
          <b-icon
            icon="dot"
          />
          {{ account.name }}
        </b-list-group-item>
      </b-list-group>

      <div class="d-flex align-items-center mx-1 my-1 justify-content-between">
        <strong class="flex-grow-1">광고계정</strong>
        <b-button
          v-if="!isAdmin"
          v-b-modal.modal-ad-account
          style="margin-left: auto"
          pill
          size="sm"
          variant="primary"
        >
          <b-icon
            icon="plus"
          />
          추가
        </b-button>
      </div>
      <b-list-group
        flush
        class="account-group"
      >
        <b-list-group-item
          v-for="account in adAccounts"
          :key="account.id"
          :active="account.id.toString() === current.id && account.type === current.type"
          :disabled="account.status==='DISABLED'"
          button
          @click="onSelectAdAccount(account)"
        >
          <span>
            <b-icon
              icon="circle-fill"
              scale="0.2"
            />
          </span>
          {{ account.name }}
        </b-list-group-item>
      </b-list-group>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-row class="d-flex justify-content-between">
        <b-button
          variant="link"
        >
          사용자 가이드
        </b-button>
        <b-button
          variant="link"
          @click="logout"
        >
          로그아웃
        </b-button>
      </b-row>
    </li>

    <create-channel-account-modal />
    <create-ad-account-modal />
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BIcon, BRow, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed, inject } from '@vue/composition-api'
import { adAbility, channelAbility, initialAbility } from '@/libs/acl/config'
import { channelRouteNames } from '@/router/routes/channel'
import { adRouteNames } from '@/router/routes/ad'
import { isManager } from '@/libs/acl/protection'
import UserAvatar from './UserAvatar.vue'
import CreateChannelAccountModal from './CreateChannelAccountModal.vue'
import CreateAdAccountModal from './CreateAdAccountModal.vue'

export default {
  components: {
    VuePerfectScrollbar,
    UserAvatar,
    BRow,
    BNavItemDropdown,
    BButton,
    BIcon,
    BListGroup,
    BListGroupItem,
    CreateChannelAccountModal,
    CreateAdAccountModal,
  },
  computed: {
    current() {
      return this.$store.state.account.currentAccount
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/signOut').then(() => {
        this.$ability.update(initialAbility)
        this.$store.dispatch('account/onSelectAccount', {
          id: null,
          type: null,
          name: '',
          permission: null,
        })
        this.$router.push({ name: 'auth-login' })
      })
    },
    onSelectChannelAccount(account) {
      this.$refs['user-dropdown'].hide(true)
      this.$store.dispatch('account/onSelectAccount', account)
      this.$ability.update(channelAbility(account.permission))
      this.$router.push({
        name: channelRouteNames.account,
        params: {
          channelId: account.id,
        },
      })
    },
    onSelectAdAccount(account) {
      this.$refs['user-dropdown'].hide(true)
      this.$store.dispatch('account/onSelectAccount', account)
      this.$ability.update(adAbility(account.permission))
      this.$router.push({
        name: adRouteNames.account,
        params: {
          adId: account.id,
        },
      })
    },
  },
  setup() {
    const userData = inject('userData')
    const channelAccounts = inject('channelAccounts')
    const adAccounts = inject('adAccounts')
    const isAdmin = computed(() => isManager())
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      isAdmin,
      userData,
      channelAccounts,
      adAccounts,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" scoped>
.account-name {
  margin-bottom: 10px;
}

.account-group {
  min-height: 40px;
}
</style>
