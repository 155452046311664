/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
*/

// Array of sections
import { channelRouteNames } from '@/router/routes/channel'
import { adRouteNames } from '@/router/routes/ad'
import { adminRouteName } from '@/router/routes/admin'

export default [
  {
    title: 'Slot',
    route: channelRouteNames.slot,
    action: 'read',
    resource: 'Channel',
    icon: 'GridIcon',
  },
  {
    title: 'ChannelAccount',
    route: channelRouteNames.account,
    action: 'read',
    resource: 'Channel',
    icon: 'UserIcon',
  },
  {
    title: 'Campaign',
    route: adRouteNames.campaign,
    action: 'read',
    resource: 'Ad',
    icon: 'LayoutIcon',
  },
  {
    title: 'AdAccount',
    route: adRouteNames.account,
    action: 'read',
    resource: 'Ad',
    icon: 'UserIcon',
  },
  {
    title: 'ChannelAccountAdmin',
    route: adminRouteName.channelAccount,
    action: 'manage',
    resource: 'Admin',
    icon: 'UsersIcon',
  },
  {
    title: 'SlotAdmin',
    route: adminRouteName.slot,
    action: 'manage',
    resource: 'Admin',
    icon: 'GridIcon',
  },
  {
    title: 'AdAccountAdmin',
    route: adminRouteName.adAccount,
    action: 'manage',
    resource: 'Admin',
    icon: 'UsersIcon',
  },
  {
    title: 'CampaignAdmin',
    route: adminRouteName.campaign,
    action: 'manage',
    resource: 'Admin',
    icon: 'LayoutIcon',
  },
  {
    title: 'MembersAdmin',
    icon: 'UsersIcon',
    route: adminRouteName.members,
    action: 'manage',
    resource: 'Admin',
  },
]
